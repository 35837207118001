import { MDXProvider } from "@mdx-js/react";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import TimeAgo from "javascript-time-ago";
import nl from "javascript-time-ago/locale/nl.json";
import React, { useContext, useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import styled from "styled-components";

TimeAgo.addDefaultLocale(nl);

import { FirebaseContext } from "../../components/Firebase";
import {
  ContentBox,
  ContentBg,
  Input,
  Button,
  Instagram,
  Carousel,
  Gallery,
  YouTube,
  Vimeo,
  InlineImage,
} from "../../components/ui";

import Seo from "../../components/seo";
import Comments from "../../components/blog/comments";

const shortcodes = {
  Carousel,
  Gallery,
  Instagram,
  YouTube,
  Vimeo,
  InlineImage,
};

const StyledContextBox = styled(ContentBox)`
  width: 50vw;
  @media screen and (max-width: 768px) {
    width: 74vw;
  }
`;

const StyledDate = styled.small`
  float: right;
  color: #333;
  font-style: italic;
  text-align: right;
`;
const StyledNavLinks = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  a:first-child {
    margin-right: 0.4rem;
  }
`;
const StyledTextarea = styled.textarea`
  width: 100%;
  height: 60vh;
  border: 1px solid #f60;
  border-radius: 5px;
  padding: 0.3rem;
  margin-bottom: 2rem;
`;

const BlogPost = props => {
  const [editMode, setEditMode] = useState(false);

  const { data } = props;
  const {
    allMdx: { nodes },
    mdx,
  } = data;

  const {
    frontmatter: { title, publishedAt, embeddedImages },
    id,
    body,
    slug,
  } = mdx;

  const { firebase, user } = useContext(FirebaseContext);
  const articleIndex = nodes.findIndex(node => node.id === id);
  const prevArticle = nodes[articleIndex - 1];
  const nextArticle = nodes[articleIndex + 1];
  const firstImageSrc = !embeddedImages
    ? ""
    : embeddedImages[0].childImageSharp?.full?.images?.fallback?.src;

  const savePost = e => {
    e.preventDefault();

    const post = e.target.post.value.trim();
    const title = e.target.title.value.trim();
    const utc = Date.now();
    const id = slug.split("/")[1]; // remove 'year' prefix

    // setDoc(doc(firebase.db, "blog", slug), {
    //   title,
    //   embeddedImages, // < this won't work/update
    //   slug,
    //   publishedAt,
    //   lastUpdated: utc,
    //   body: post,
    // }).then(() => {
    //   getDoc(doc(firebase.db, "blog", slug)).then(async docSnap => {
    //     const result = await docSnap.data();
    //     console.log(result);
    //     setActiveTitle(result.title);
    //     setActiveBody(result.body);
    //     setEditMode(false);
    //   });
    // });
  };

  return (
    <ContentBg src={firstImageSrc}>
      <StyledContextBox>
        <Seo title={title} />
        <StyledDate>
          {new Date(publishedAt).toLocaleDateString("nl-NL", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
          <br />
          (<ReactTimeAgo date={publishedAt} locale="nl" />)
        </StyledDate>

        {user && user.userId === process.env.GATSBY_FIREBASE_ADMIN_UID ? (
          <h1>
            {title}{" "}
            <span
              onClick={() => setEditMode(true)}
              style={{ cursor: "pointer" }}
            >
              ✍🏻
            </span>
          </h1>
        ) : (
          <h1>{title}</h1>
        )}

        {user &&
          user.userId === process.env.GATSBY_FIREBASE_ADMIN_UID &&
          !!editMode && (
            <form onSubmit={savePost}>
              <Button style={{ float: "right" }}>Save</Button>
              <Button
                style={{ float: "right" }}
                onClick={() => setEditMode(false)}
              >
                Cancel
              </Button>
              <Input defaultValue={title} name="title" placeholder="Title" />
              <StyledTextarea name="post" defaultValue={rawBody} />
            </form>
          )}

        <MDXProvider components={shortcodes}>
          <MDXRenderer componentImages={embeddedImages}>{body}</MDXRenderer>
          <hr />
          {!!firebase && <Comments firebase={firebase} slug={slug} />}
          <hr />
          <StyledNavLinks>
            {prevArticle && (
              <Link
                to={`/article/${prevArticle.slug}`}
                title={prevArticle.frontmatter.title}
              >
                &lt; {prevArticle.frontmatter.publishedAt}
              </Link>
            )}
            {nextArticle && (
              <Link
                to={`/article/${nextArticle.slug}`}
                title={nextArticle.frontmatter.title}
              >
                {nextArticle.frontmatter.publishedAt} &gt;
              </Link>
            )}
          </StyledNavLinks>
        </MDXProvider>
      </StyledContextBox>
    </ContentBg>
  );
};

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        publishedAt(formatString: "MMMM D, YYYY")
        hero
        embeddedImages {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 300
              height: 300
              placeholder: BLURRED
              layout: FIXED
            )
            full: gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        photos
      }
      body
      slug
      id
      rawBody
    }
    allMdx(sort: { fields: frontmatter___publishedAt, order: ASC }) {
      nodes {
        frontmatter {
          publishedAt(formatString: "MMMM D, YYYY")
          title
        }
        id
        slug
      }
    }
  }
`;

export default BlogPost;
