import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Button, Input } from "../ui";

const CommentForm = styled.form`
  display: flex;
  margin-top: 32px;

  ${Input} {
    margin-right: 8px;
    margin-top: auto;
    margin-bottom: auto;
  }

  ${Button} {
    margin: auto 0;
  }
`;

const CommentListItem = styled.div`
  > strong {
    font-size: 80%;
    color: #666;
  }

  border-bottom: 1px solid #ddd;
  padding: 4px 0;
`;

export default ({ firebase, slug }) => {
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");

  useEffect(() => {
    // KEEPING THIS AS REFERENCE FOR STATIC DATA (NON REALTIME)
    // firebase.getBlogPostComments({ slug }).then(comments => {
    //   setComments(comments);
    // });

    const unsubscribe = firebase.subscribeToBlogPostComments({
      slug,
      onSnap: querySnapshot => {
        const snapshotComments = [];
        querySnapshot.forEach(doc => {
          snapshotComments.push({ ...doc.data() });
        });
        setComments(snapshotComments);
      },
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  function handlePostCommentSubmit(e) {
    e.preventDefault();
    firebase.postComment({
      text: commentText,
      slug,
    });
  }

  return (
    <div>
      <CommentForm onSubmit={handlePostCommentSubmit}>
        <Input
          value={commentText}
          placeholder="Please login first..."
          onChange={e => {
            e.persist();
            setCommentText(e.target.value);
          }}
        />
        <Button type="submit">Post&nbsp;comment</Button>
      </CommentForm>
      {comments.map(comment => (
        <CommentListItem key={comment.id}>
          <strong>{comment.username}</strong>
          <div>{comment.text}</div>
        </CommentListItem>
      ))}
    </div>
  );
};
